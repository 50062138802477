<template lang="html">
  <div class="component card">
    <div class="title">
      <h1>Your Alerts</h1>
    </div>
    <vue-good-table 
      :columns="columns"
      :rows="alerts"
      :row-style-class="rowStyleClassFunction"
      max-height="25em">
      <div slot="emptystate"><p>You don't have any alerts yet.</p><p>If you just checked out, it can take a few moments for us to process your payment and enable your alert. Try refreshing!</p></div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'product'">
          <product-item :product="props.row.product"></product-item>
          <!-- <span class="product"><b><a :href="props.row.product.product_url" target="_blank">{{props.row.product.name}}</a></b> • <a :href="'stock-reports/' + props.row.product.retailer.toLowerCase() + '/' + props.row.product.item_id" target="_blank">📊 View history</a></span>  -->
        </span>
        <span v-else-if="props.column.field == 'toggle'">
          <input type="checkbox" id="checkbox" v-bind:checked="!props.row.is_paused" :disabled="props.row.is_expired || loadingId === props.row.id" @change="toggleAlert(props.row)">
        </span>        
        <span v-else-if="props.column.field == 'delete'">
          <span class="delete"><a v-if="loadingId != props.row.id" @click="confirmDeleteAlert(props.row)">Delete</a></span> 
        </span>        
        <span v-else-if="props.column.field == 'expires_at'">
          <span v-if="props.row.expires_at">{{props.formattedRow[props.column.field]}}</span>
          <span v-else>No expiry</span>
        </span>       
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>

    </vue-good-table>
    <router-link to="/" tag="button" class="big-button">Create a new alert</router-link>
  </div>
</template>

<script>
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import ProductItem from './product-item.vue';

export default {
  components: {VueGoodTable, ProductItem},
  data() {
    return {
      loadingId: null,
      columns: [
        {
          label: "Product",
          field: "product",
        },
        {
          label: "Retailer",
          field: "product.retailer",
        },
        {
          label: "Status",
          field: this.statusText,
          width: '4em',
        },
        {
          label: "Last checked",
          type: 'date',
          field: 'last_checked',
          dateInputFormat: 't', // expects 2018-03-16
          dateOutputFormat: 'p • P', // outputs Mar 16th 2018
          width: '10em',
        },
        {
          label: "Created",
          type: 'date',
          field: "created",
          dateInputFormat: 't', // expects 2018-03-16
          dateOutputFormat: 'MMM do yyyy', // outputs Mar 16th 2018
          width: '6em',
        },
        {
          label: "Expires?",
          type: 'date',
          field: 'expires_at',
          dateInputFormat: 't', // expects 2018-03-16
          dateOutputFormat: 'MMM do yyyy', // outputs Mar 16th 2018
          width: '6em',
          tooltip: 'Only applies to by-the-bottle alerts.',
        },
        {
          label: "Enabled",
          field: 'toggle',
          sortable: false,
          width: '4em',
        },
        {
          label: "🗑",
          field: 'delete',
          sortable: false,
          width: '4em',
        },
      ]
    }
  },
  methods: {
    rowStyleClassFunction(row) {
      return row.is_active ? 'active' : 'inactive';
    },
    statusText(row) {
      if (row.id === this.loadingId) {
        return "Loading…";
      } else if (row.is_expired) {
        return "Expired";
      } else if (row.is_paused) {
        return "Paused";
      } else if (row.is_active) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
    rowToggleText(row) {
      // for the label of toggling pause / resume
      if (row.is_expired) {
        return "Expired";
      } else if (row.is_paused) {
        return "Resume";
      } else if (!row.is_paused) {
        return "Pause";
      }
    },
    toggleAlert(alert) {
      this.loadingId = alert.id;

      this.$store.dispatch('pauseAlert', alert).then((response) => {
        console.log("Paused alert")
        this.loadingId = null;
      }).catch((error) => {
        console.log("Couldn't pause it");
        this.errorMessage = error.response.data;
        this.loadingId = null;
      });
    },
    deleteAlert (alert) {
      this.loadingId = alert.id;

      this.$store.dispatch('deleteAlert', alert).then((response) => {
        console.log("Deleted alert")
        this.loadingId = null;
      }).catch((error) => {
        console.log("Couldn't delete it");
        this.loadingId = null;
      });
    },
    confirmDeleteAlert(alert) {
      // TODO: show a thingy
      let message = 'Are you sure you want to delete your alert for ' + alert.product.name + "? You can't undo this.";
      if (confirm(message)) {
        this.deleteAlert(alert);
      }
    }

},
  computed: {
    alerts: function() {
      return this.$store.state.alerts;
    },
    hasAlerts: function() {
      return this.$store.state.alerts.length != 0;
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: 2em auto;
  max-width: 90%;
  padding-left: 0;
  padding-right: 0;
  padding: 10px 0;
}
.alerts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.vgt-wrap {
  margin-bottom: 1em;
  .inactive {
    text-decoration: underline;
  }
  .product {
    a {
      // color: red;
    }
  }

  span {
    a {
      color: $link;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        cursor:pointer;
      }
    }
  }
}

</style>
